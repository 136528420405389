import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const AltstadtNord: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.AltstadtNord,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Altstadt-Nord"
          d="M568,588L568,588c-4,8-6.8,16.4-8.4,25.2l0,0c-1.1,5.8-1.4,11.8-1,17.7L560,652h-20l-2-6h-24l-2-6h-8l-4,6 l-16.8-0.6c-5.1-49.8,39.6-59.3,54.8-61.4C538,584,568,588,568,588z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 501.6621 615.501)"
          textRendering="geometricPrecision"
        >
          <tspan x="0" y="0" className="st6 st3 st4">
            Altstadt-
          </tspan>
          <tspan x="0" y="14.4" className="st6 st3 st4">
            Nord
          </tspan>
        </text>
      </g>
    </>
  );
});
